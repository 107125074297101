import './css/Header.css';
import '@rainbow-me/rainbowkit/styles.css';
import { BrowserRouter as Router, Link,NavLink, Route, Routes } from 'react-router-dom';
import {
    ConnectButton, connectorsForWallets,
    darkTheme,
    getDefaultWallets,
    RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import {
    coinbaseWallet,
    injectedWallet, ledgerWallet,
    metaMaskWallet, okxWallet,
    rainbowWallet, trustWallet,
    walletConnectWallet
} from "@rainbow-me/rainbowkit/wallets";


// @ts-ignore
const { chains, provider } = configureChains(
    [mainnet],
    [
        alchemyProvider({ apiKey: "YKJC5X8ipCEFHEjKc5LzhfKBL9aJj38m" }),
        publicProvider()
    ]
);
const projectId = '32ae4df17a79d7a5e24a699e99106b54'

const connectors = connectorsForWallets([
    {
        groupName: 'Recommended',
        wallets: [
            injectedWallet({ chains }),
            metaMaskWallet({ projectId, chains }),
            rainbowWallet({ projectId, chains }),
            coinbaseWallet({ chains, appName: 'My RainbowKit App' }),
            okxWallet({projectId,chains}),
            ledgerWallet({projectId,chains}),
            trustWallet({projectId,chains}),
            walletConnectWallet({projectId,chains})
        ],
    },
]);

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
})


function Home() {
    return null;
}

function Vision() {
    return null;
}

function Roadmap() {
    return null;
}

function Header() {
    return (
        <Router>
            <WagmiConfig client={wagmiClient}>
                <RainbowKitProvider
                    coolMode
                    chains={chains}
                    theme={darkTheme({
                        accentColor: 'rgba(26,48,84,0.8)',
                        accentColorForeground: 'white',
                        borderRadius: 'medium',
                        overlayBlur: 'small'
                    })}
                >
                    <header className="Header">
                        <nav className="Header-nav">
                            <div className="d0">
                                <Link to="/">
                                    <img src="/img/LOGO.jpg" alt="logo" />
                                </Link>
                                <div style={{width: 'auto',height: '50px',fontSize: '30px',fontWeight: "bold",marginTop: '5px'}} > AIAV</div>
                            </div>
                            <div className="d1" style={{paddingRight: "30px"}}>
                                <ul className="Header-navList">
                                    <li>
                                        <NavLink to="/Home" className={({ isActive }) => (isActive ? 'selected navLink' : 'no_selected')}>
                                            Home
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Vision" className={({ isActive }) => (isActive ? 'selected navLink' : 'no_selected')}>
                                            Vision
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Roadmap" className={({ isActive }) => (isActive ? 'selected navLink' : 'no_selected')}>
                                            Roadmap
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="d2">
                                <ConnectButton accountStatus="address" showBalance={false} />
                            </div>
                        </nav>
                    </header>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/Home" element={<Home />} />
                        <Route path="/Vision" element={<Vision />} />
                        <Route path="/Roadmap" element={<Roadmap />} />
                    </Routes>
                </RainbowKitProvider>
            </WagmiConfig>
        </Router>
    );
}


export default Header;
