import React from "react";

export const vision = (
    <div style={{
        fontFamily: "Sans-serif",
        display: 'flex',
        border: '6px solid #ccc',
        borderRadius: '10px', // 添加圆角
        height: 'auto',
        width: '800px',
        margin: '100px',
    }}>
        <div style={{ width: '50%', padding: '20px' ,fontSize: '17px'}}>
            <div style={{ color: 'white',fontWeight: 'bolder'}}>
                Struggling as an otaku to afford the latest Japanese AV content? Your wait is over!
                The AIAV Token is here to revolutionize your adult entertainment experience.
                Powered by an innovative ChatGPT-backed contract,
                our goal is to enable every fan to indulge in their favorite AV,
                regardless of their financial situation.
            </div>
            <div style={{ color: 'white', fontWeight: 'bolder'}}>
                <br/>
                The AIAV Token is more than just a digital currency;
                it's a movement to dismantle barriers and make AV accessible to all.
                So, what are you waiting for?
                Embrace your inner otaku and join the AIAV Token revolution!
                Together, let's make adult entertainment a reality for every fan!
            </div>
        </div>
        <div style={{ width: '50%' }}>
            <img src="/img/P1.png" style={{ maxWidth: '400px', height: '400px' }} />
        </div>
    </div>
);
