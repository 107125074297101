import React from 'react';
import './css/Home.css';
import './components/homeComp'
import {buttonBuy, topText} from "./components/homeComp";
import {vision} from "./components/vision";
function Home() {
    return (
        <>
            <div className = 'Home'>
                <div className='Main'>
                    { topText }
                    { buttonBuy  }
                </div>
                <div>
                    { vision }
                </div>
                <div>

                </div>
                <div>

                </div>
                <div>

                </div>
                <div>

                </div>
            </div>
        </>
    );
}

export default Home;
