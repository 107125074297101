import React from "react";

export const topText = (
    <div style={{fontFamily: "Sans-serif"}}>
        <div style={{ color: "lightgray", textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}}>
            Genesis Launch on Arbitrum
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{ color: 'white', textAlign: 'center', fontSize: '50px', fontWeight: 'bolder'}}>
                Not just a digital currency.
            </div>
            <div style={{ color: 'white', textAlign: 'center', fontSize: '50px', fontWeight: 'bolder'}}>
                A movement to dismantle barriers
                {/*<span style={{ color: 'red'}}>Community</span>*/}
            </div>
            <div style={{ color: 'white', textAlign: 'center', fontSize: '50px', fontWeight: 'bolder'}}>
                Make AV accessible to all
                {/*<span style={{ color: 'red'}}>Community</span>*/}
            </div>
        </div>
    </div>
);
export const buttonBuy = (
    <button className= 'buttonBuy'
            onMouseOver={(e) => {
                const target = e.target as HTMLElement;
                target.style.backgroundColor = 'rgb(100,215,132)';
                // target.innerText = 'MINT NOW'
            }}
            onMouseOut={(e) => {
                const target = e.target as HTMLElement;
                target.style.backgroundColor = 'rgba(90,192,119,0.85)';
                // target.innerText = 'MINT NOW'
            }}
    >
        JUST WAIT
    </button>
);
