import React from 'react';
import './css/App.css';
import Header from "./Header";
import Home from "./Home";
// @ts-ignore
// import { Helmet } from "react-helmet";
function App() {
    return (
        <div className="App">
                <title>$AIAV</title>
            <Header/>
            <div className="Content">
                <Home/>
            </div>
        </div>
    );
}

export default App;
